import React, { createContext, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useSnackbar } from 'src/components/snackbar';
import { fetchDataOn } from 'src/helpers/utils';

export const CrudContext = createContext({});

export const CrudProvider = ({id, columns, cod_pk_name, endpoint, title, optionsButtonsList, children}) =>
{
    const { enqueueSnackbar } = useSnackbar();

    const [selectedRow, setSelectedRow] = useState(null)
    const [navItemsFilter, setNavItemsFilter] = useState([])
    const [filtered, setFiltered] = useState(null);

    const getData = async () => 
    {
        let response = await fetchDataOn(endpoint, null, 'get');

        if (response.status)
        {
            response.result = response.result.map((item, i) => 
            {
                item.id = i
                return item;
            })
        }

        return response;
    };

    const { data, isFetching, isError, error, refetch } = useQuery([id], async () => await getData());

    useEffect(() =>
    {
        if (isError)
            console.log(error)
    }, 
    // eslint-disable-next-line
    [isError]);

    useEffect(() =>
    {
        if (data)
        {
            const { status, result } = data;

            if (status)
            {
                let items = [
                    {"id":"all","type":"system","name":"total","count": result.length},
                ];

                columns.filter(col => col.navItem).forEach(col => 
                {
                    const { navItem } = col;
                    const { id, name, type, values } = navItem; 

                    if (type === "groupBy")
                    {
                        values.forEach(value => 
                        {
                            const count = result.filter(x => x[id] === value.value).length;
                            let obj = {id, type:"system", name: value.value ,"count": count, origin: col.navItem};

                            if (value.icon)
                                obj.icon = value.icon;

                            if (value.color)
                                obj.color = value.color;

                            items.push(obj)
                        });
                    }
                    else 
                    if (type === "sum")
                    {
                        var resultTemp = [];
                        values.forEach((item, i) => 
                        {
                            if (item.operator === "!==")
                            {
                                resultTemp = (i === 0) 
                                ? result.filter(x => x[item.col] !== item.value)
                                : resultTemp.filter(x => x[item.col] !== item.value);
                            }
                            else 
                            {
                                resultTemp = (i === 0) 
                                ? result.filter(x => x[item.col] === item.value)
                                : resultTemp.filter(x => x[item.col] === item.value);
                            }
                        });

                        let obj = {id, type:"system", name ,"count": resultTemp.length, origin: col.navItem};

                        if (navItem.icon)
                            obj.icon = navItem.icon;

                        if (navItem.color)
                            obj.color = navItem.color;

                        items.push(obj)
                    }
                });

                setNavItemsFilter(items);
            }
            else
                enqueueSnackbar(result, { variant: 'error'});
        }
    }, 
    // eslint-disable-next-line
    [data]);

    useEffect(() =>
    {
        if (isError)
            enqueueSnackbar(error, { variant: 'error'});
    }, 
    // eslint-disable-next-line
    [isError]);

    // const saveFilterState = () =>
    // {
    //     const data = {
    //         col: colSelectedFilter,
    //         value: keyWord
    //     };

    //     localStorage.setItem(`${id}-filter`, JSON.stringify(data))
    // }

    return (
        <CrudContext.Provider
            value={
            {
                id, title,
                cod_pk_name,
                columns, 
                data, isFetching, isError, error, refetch,
                selectedRow, setSelectedRow,
                navItemsFilter,
                filtered, setFiltered,
                optionsButtonsList
            }}
        >
            {children}
        </CrudContext.Provider>
    )
}