import React from 'react';
import { CircularProgress } from '@mui/material';

const Simple = ({visible=true, message="Carregando"}) =>
{
    return visible &&
    <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', padding: 10}}>
        <CircularProgress /> <span style={{margin: 10}}>{message}...</span>
    </div>
}

export default Simple