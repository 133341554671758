import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { Stack } from '@mui/material';
import { DataGrid, gridPageCountSelector, gridPageSelector, ptBR, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { Pagination } from '@mui/material';

import Iconify from 'src/components/iconify/Iconify';
import EmptyContent from 'src/components/empty-content/EmptyContent';

import { APP_PATH_MAIN } from 'src/config/constants';

function SortedDescendingIcon() { return <Iconify icon="typcn:arrow-sorted-down" /> }
function SortedAscendingIcon() { return <Iconify icon="typcn:arrow-sorted-up" /> }
function Empty() { return <EmptyContent title="Nenhum registro foi encontrado" img={APP_PATH_MAIN + "/assets/illustrations/illustration_empty_mail.svg"} /> }

function CustomPagination(props) 
{
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
        <Stack spacing={1} sx={{mt: 2, mr: 1, ml: 1, mb: 1}}>
            <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={page + 1}
                count={pageCount}
                // @ts-expect-error
                // renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                onChange={(event, value) => apiRef.current.setPage(value - 1)} 
            />
        </Stack>
    );
}

const Index = ({
    columns=[],
    data=null,
    height=400,
    loading=false,
    filtered,
    isCompact=false,
    style,
    toolbar,
    onRowClick=(e) => null,
    onCellKeyDown = (params, event, details) => null//console.log(params, event, details)
}) =>
{
    const [dataTable, setDataTable] = useState([]);

    useEffect(() =>
    {
        defineDataTable();
    }, 
    // eslint-disable-next-line
    [data]);

    useEffect(() =>
    {
        if (filtered)
            defineDataTable();
    }, 
    // eslint-disable-next-line
    [filtered]);

    const defineDataTable = () =>
    {
        if (data)
        {  
            const { status, result } = data;

            if (status)
                setDataTable(filtered ?? result.map((item, i) =>
            {
                item.id = i;
                return item;
            }));
        }
    }

    return (
        <div style={{ height: height, width: '100%' }}>
            <DataGrid
                className='bg-light'
                style={style}
                rows={dataTable}
                columns={columns.map(col =>
                    {
                        return {
                            field: col.id,
                            headerName: col.title,
                            width: col.width ?? 60,
                            sortable: col.sortable ?? true,
                            renderCell: col.renderCell
                        }
                    })
                }
                // pageSize={100}
                // rowsPerPageOptions={[100]}
                // pagination
                // slots={{
                //     pagination: CustomPagination,
                // }}
                disableColumnMenu
                disableSelectionOnClick
                loading={loading}
                onRowClick={onRowClick}
                onCellKeyDown={onCellKeyDown}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                density={isCompact ? 'compact' : 'standard'} 
                components={{ 
                    Toolbar: toolbar,
                    ColumnSortedDescendingIcon: SortedDescendingIcon,
                    ColumnSortedAscendingIcon: SortedAscendingIcon,
                    NoRowsOverlay: Empty,
                    NoResultsOverlay: Empty,
                    Footer: CustomPagination
                    // LoadingOverlay: LinearProgress
                }} 
                componentsProps={{
                    toolbar: toolbar && {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                // hideFooter
                // getRowHeight={() => 'auto'}        
                // sx={{
                //     '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '5px' },
                //     '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
                //     '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '15px' },
                // }}   
            />
        </div>
    )
}

Index.prototype = {
    columns: PropType.array,
    data: PropType.array, 
    height: PropType.number,
    loading: PropType.bool,
    filtered: PropType.array,
    isCompact: PropType.bool,
    toolbar: PropType.element,
    style: PropType.object,
    onRowClick: PropType.func,
    onCellKeyDown: PropType.func
}

export default Index;