import { Navigate, useRoutes } from 'react-router-dom';

import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import { 
	Address, AddressEdit,
	Cashback,
	Coupon, CouponDelete, CouponSale,
	Commission, CommissionEdit, CommissionDelete,
	Dashboard,
	EventPendent,
	LoginPage,
	Graduation,
	Page403, Page404,
	Participant, ParticipantEdit, ParticipantIndicated, ParticipantEvent, ParticipantDelete,
	Reward,
	Sale,
	User, UserEdit, UserDelete, UserProfile, UserLogout, 
	Tray, TrayEdit,
} from './elements';
import { EventProvider } from 'src/context/EventProvider';

export default function Router() 
{
	return useRoutes([
		{
			path: '/',
			children: [
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				{
					path: 'login',
					element: (
						<GuestGuard>
							<LoginPage />
						</GuestGuard>
					),
				},
			],
		},
		{
			//path: '/dashboard',
			path: '/',
			element: (
				<AuthGuard>
					<EventProvider>
						<DashboardLayout />
					</EventProvider>
				</AuthGuard>
			),
			children: [
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				{
					path: 'address',
					children: [
						{ element: <Navigate to="/address/list" replace />, index: true },
						{ path: 'list', element: <Address /> },
						{ path: 'insert', element: <AddressEdit /> },
						{ path: 'edit/:id', element: <AddressEdit /> },
					],
				},
				{
					path: 'cashback',
					children: [
						{ element: <Navigate to="/cashback/list" replace />, index: true },
						{ path: 'list', element: <Cashback /> },
					],
				},
				{
					path: 'coupon',
					children: [
						{ element: <Navigate to="/coupon/list" replace />, index: true },
						{ path: 'redeem', element: <Coupon /> },
						{ path: 'sale', element: <CouponSale /> },
						{ path: 'delete/:id', element: <CouponDelete /> },
					],
				},
				{
					path: 'commission',
					children: [
						{ element: <Navigate to="/commission/list" replace />, index: true },
						{ path: 'list', element: <Commission /> },
						{ path: 'insert', element: <CommissionEdit /> },
						{ path: 'edit/:id', element: <CommissionEdit /> },
						{ path: 'delete/:id', element: <CommissionDelete /> },
					],
				},
				{
					path: 'dashboard',
					children: [
						{ element: <Navigate to="/dashboard" replace />, index: true },
						{ path: 'list', element: <Dashboard /> },
					],
				},
				{
					path: 'event',
					children: [
						{ element: <Navigate to="/event" replace />, index: true },
						{ path: 'pendent', element: <EventPendent /> },
					],
				},
				{
					path: 'graduation',
					children: [
						{ element: <Navigate to="/graduation/list" replace />, index: true },
						{ path: 'list', element: <Graduation /> },
					],
				},
				{
					path: 'participant',
					children: [
						{ element: <Navigate to="/participant/list" replace />, index: true },
						{ path: 'list', element: <Participant /> },
						{ path: 'list/graduation/:id', element: <Participant /> },
						{ path: 'insert', element: <ParticipantEdit /> },
						{ path: 'edit/:id', element: <ParticipantEdit /> },
						{ path: 'delete/:id', element: <ParticipantDelete /> },
						{ path: 'indicated/:id', element: <ParticipantIndicated /> },
						{ path: 'event', element: <ParticipantEvent /> },
						{ path: 'event/:id', element: <ParticipantEvent /> },
					],
				},
				{
					path: 'sale',
					children: [
						{ element: <Navigate to="/sale/list" replace />, index: true },
						{ path: 'list', element: <Sale /> },
					],
				},
				{
					path: 'reward',
					children: [
						{ element: <Navigate to="/reward/list" replace />, index: true },
						{ path: 'list', element: <Reward /> },
					],
				},
				{
					path: 'tray',
					children: [
						{ element: <Navigate to="/tray/list" replace />, index: true },
						{ path: 'list', element: <Tray /> },
						{ path: 'insert', element: <TrayEdit /> },
						{ path: 'edit/:id', element: <TrayEdit /> },
					],
				},
				{
					path: 'user',
					children: [
						{ element: <Navigate to="/user/list" replace />, index: true },
						{ path: 'list', element: <User /> },
						{ path: 'insert', element: <UserEdit /> },
						{ path: 'edit/:id', element: <UserEdit /> },
						{ path: 'delete/:id', element: <UserDelete /> },
						{ path: 'profile', element: <UserProfile /> },
						{ path: 'logout', element: <UserLogout /> },
					],
				},
			],
		},
		{
			element: <CompactLayout />,
			children: [
				{ path: '403', element: <Page403 /> },
				{ path: '404', element: <Page404 /> },
			],
		},
		{ path: '*', element: <Navigate to="/404" replace /> },
		{ path: '403', element: <Navigate to="/403" replace /> },
		{ path: 'map', element: <Navigate to="/map" replace /> },
	]);
}
