import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';

import { useAuthContext } from 'src/auth/useAuthContext';
import { CustomAvatar } from 'src/components/custom-avatar';
import { useSnackbar } from 'src/components/snackbar';
import { IconButtonAnimate } from 'src/components/animate';
import { confirmAlert } from 'react-confirm-alert';
import { APP_NAME } from 'src/config/constants';

import MenuPopover from 'src/components/menu-popover';
import Iconify from 'src/components/iconify/Iconify';

const OPTIONS = [
	{
		label: 'Perfil',
		linkTo: '/user/profile',
		icon: 'carbon:user-profile'
	},
];

export default function AccountPopover()  
{
	const navigate = useNavigate();
 
	const queryClient = useQueryClient();
	
	const { user, logout } = useAuthContext();

	const { enqueueSnackbar } = useSnackbar();

	const [openPopover, setOpenPopover] = useState(null);

	const handleOpenPopover = (event) => setOpenPopover(event.currentTarget);

	const handleClosePopover = () => setOpenPopover(null);

	const handleLogout = () => 
	{		
		handleClosePopover();

		confirmAlert({
			title: 'Logout',
			message: `Sr(a) ${user?.nome}. Tem certeza que deseja encerrar suas atividades no ${APP_NAME}?`,
			buttons: [
				{
					label: 'Sim',
					onClick: async () =>
					{
						try 
						{
							queryClient.removeQueries();
							await logout();
							navigate("/login", { replace: true });
						} 
						catch (error) 
						{
							console.error(error);
							enqueueSnackbar('Unable to logout!', { variant: 'error' });
						}
					}
				},
				{
					label: 'Não'
				}
			]
		});
	};

	const handleClickItem = (path) => 
	{
		handleClosePopover();
		navigate(path);
	};

	return (
	<>
		<IconButtonAnimate
			onClick={handleOpenPopover}
			sx={
			{
				p: 0,
				...(openPopover && 
				{
					'&:before': {
						zIndex: 1,
						content: "''",
						width: '100%',
						height: '100%',
						borderRadius: '50%',
						position: 'absolute',
						bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
					},
				}),
			}}
		>
			<CustomAvatar src={user?.photoURL} alt={user?.nome} name={user?.nome} />
		</IconButtonAnimate>
		<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
			<Box sx={{ my: 1.5, px: 2.5 }}>
				<Typography variant="subtitle2" noWrap>
					{user?.nome}
				</Typography>
				<Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
					{user?.graduacao}: {user?.email}
				</Typography>
			</Box>
			<Divider sx={{ borderStyle: 'dashed' }} />
			<Stack sx={{ p: 1 }}>
				{OPTIONS.map((option) => (
					<MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
						{option.icon ? <Iconify icon={option.icon} /> : ''}{option.label}
					</MenuItem>
				))}
			</Stack>
			<Divider sx={{ borderStyle: 'dashed' }} />
			<MenuItem onClick={handleLogout} sx={{ m: 1 }}>
				<Iconify icon="material-symbols:logout" />Sair
			</MenuItem>
		</MenuPopover>
	</>);
}
