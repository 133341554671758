import { PATH_DASHBOARD } from 'src/routes/paths';

export const navConfig = [
	// GENERAL
	// ----------------------------------------------------------------------
	{
		id: 'principal',
		subheader: 'principal',
		items: [
			{ id: 'dashboard', title: 'Dashboard', path: PATH_DASHBOARD.dashboard.list, icon: "material-symbols:analytics-rounded" },
		]
	},

	// MANAGEMENT
	// ----------------------------------------------------------------------
	{
		id: 'register',
		subheader: 'cadastros',
		items: 
		[
			{
				id: 'participant',
				title: 'Afiliados',
				path: PATH_DASHBOARD.participant.root,
				icon: "material-symbols:person",
				children: [
					{ id: 'list', title: 'Consultar', path: PATH_DASHBOARD.participant.list },
					{ id: 'register', title: 'Cadastrar', path: PATH_DASHBOARD.participant.insert },
				],
			},
			{
				id: 'coupon',
				title: 'Cupons',
				path: PATH_DASHBOARD.coupon.root,
				icon: "mdi:coupon-outline",
				children: [
					{ id: 'redeem', title: 'Resgatados', path: PATH_DASHBOARD.coupon.redeem },
					{ id: 'sale', title: 'Venda', path: PATH_DASHBOARD.coupon.sale },
				],
			},
			{
				id: 'address',
				title: 'Endereços',
				path: PATH_DASHBOARD.address.root,
				icon: "entypo:address",
				children: [
					{ id: 'list', title: 'Consultar', path: PATH_DASHBOARD.address.list },
					{ id: 'register', title: 'Cadastrar', path: PATH_DASHBOARD.address.insert },
				],
			},
			{
				id: 'user',
				title: 'Usuários',
				path: PATH_DASHBOARD.user.root,
				icon: "ph:user-circle",
				children: [
					{ id: 'list', title: 'Consultar', path: PATH_DASHBOARD.user.list },
					{ id: 'register', title: 'Cadastrar', path: PATH_DASHBOARD.user.insert },
				],
			},
		],
	},

	// MANAGEMENT
	// ----------------------------------------------------------------------
	{
		id: 'adm',
		subheader: 'Administração',
		items: 
		[
			{
				id: 'graduation',
				title: 'Graduação',
				path: PATH_DASHBOARD.graduation.root,
				icon: "bxs:graduation",
				children: [
					{ id: 'list', title: 'Consultar', path: PATH_DASHBOARD.graduation.list },
				],
			},
			{
				id: 'commission',
				title: 'Comissão de Assistência à Venda',
				path: PATH_DASHBOARD.commission.root,
				icon: "iconoir:percentage-circle",
				children: [
					{ id: 'list', title: 'Consultar', path: PATH_DASHBOARD.commission.list },
					{ id: 'register', title: 'Cadastrar', path: PATH_DASHBOARD.commission.insert },
				],
			},
			{
				id: 'tray',
				title: 'Tray E-commerce API',
				path: PATH_DASHBOARD.tray.root,
				icon: "ph:archive-tray-thin",
				children: [
					{ id: 'list', title: 'Consultar', path: PATH_DASHBOARD.tray.list },
					{ id: 'register', title: 'Cadastrar', path: PATH_DASHBOARD.tray.insert },
				],
			},
		],
	},
];

export default navConfig;
