import { Alert } from '@mui/material'
import React from 'react'

const Item = ({label, type}) => 
{
    return (
        <Alert variant='filled' severity='warning'>
            {`Tipo '"${type}'" não foi implementado para o campo '${label}'.`}
        </Alert>
    )
}

export default Item