import { fetchDataOn } from "src/helpers/utils";

let Api = {
	getRowsByparticipantAndPeriod: async (cod_participante, dti, dtf ) =>
    {
		const endpoint = '/evento/list';
		const body = { cod_participante, dti, dtf };
		const method = 'post';
		return await fetchDataOn(endpoint, body, method);
    },
    getRowPendent: async () =>
    {
		const endpoint = '/evento/list/pendente';
		const body = null;
		const method = 'get';
		return await fetchDataOn(endpoint, body, method);
    },
	read: async (cod_evento) =>  
    {
        const endpoint = `/evento/read/once/${cod_evento}`;
		const body = {};
		const method = 'put';
		return await fetchDataOn(endpoint, body, method);
    },
	readAll: async () =>  
    {
        const endpoint = `/evento/read/all`;
		const body = null;
		const method = 'put';
		return await fetchDataOn(endpoint, body, method);
    },
}

export default Api;
