import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';
import jwt_decode from "jwt-decode";

import { isValidToken, setSession } from './utils';
import { APP_ALIAS } from 'src/config/constants';

import axios from '../utils/axios';
import ApiUser from 'src/services/api/user';

const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
};

const reducer = (state, action) => 
{
	if (action.type === 'INITIAL') 
	{
		return {
			isInitialized: true,
			isAuthenticated: action.payload.isAuthenticated,
			user: action.payload.user,
		};
	}

	if (action.type === 'LOGIN') 
	{
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
	}

	if (action.type === 'REGISTER') {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
  	}

	if (action.type === 'LOGOUT') {
		return {
			...state,
			isAuthenticated: false,
			user: null,
		};
	}

  	return state;
};

export const AuthContext = createContext(null);

AuthProvider.propTypes = {
  	children: PropTypes.node,
};

export function AuthProvider({ children }) 
{
	const [state, dispatch] = useReducer(reducer, initialState);

	const initialize = useCallback(async () => 
	{
		try 
		{
			const accessToken = typeof window !== 'undefined' ? localStorage.getItem(APP_ALIAS + '-accessToken') : '';

			if (accessToken && isValidToken(accessToken)) 
			{
				setSession(accessToken);

				dispatch(
				{
					type: 'INITIAL',
					payload: 
					{
						isAuthenticated: true,
						user: jwt_decode(accessToken),
					},
				});
			} 
			else 
			{
				dispatch(
				{
					type: 'INITIAL',
					payload: 
					{
						isAuthenticated: false,
						user: null,
					},
				});
			}
		} 
		catch (error) 
		{
			console.error(error);
			dispatch({
				type: 'INITIAL',
				payload: 
				{
					isAuthenticated: false,
					user: null,
				},
			});
		}
	}, []);

	useEffect(() => 
	{
		initialize();
	}, [initialize]);

	// LOGIN
	const login = async (manager, email, password) => 
	{
		localStorage.setItem(APP_ALIAS + "-company",manager);

		const resLogin = await ApiUser.login(manager, email, password);

		if (resLogin.status)
		{
			const accessToken = resLogin.result.token;

			setSession(accessToken);

			dispatch(
			{
				type: 'LOGIN',
				payload: {
					user: jwt_decode(accessToken),
				},
			});
		}
	};

	// REGISTER
	const register = async (email, password, firstName, lastName) => 
	{
		const response = await axios.post('/api/account/register', 
		{
			email,
			password,
			firstName,
			lastName,
		});
		const { accessToken, user } = response.data;

		localStorage.setItem(APP_ALIAS + '-accessToken', accessToken);

		dispatch(
		{
			type: 'REGISTER',
			payload: {
				user,
			},
		});
	};

	// LOGOUT
	const logout = async () => 
	{
		await ApiUser.logoff();

		setSession(null);

		dispatch({
			type: 'LOGOUT',
		});
	};

	// Session
	const session = async () => 
	{
		const resCurrentSession = await ApiUser.getSession();
		
		if (resCurrentSession.status)
		{
			const resLoginbyKey = { status: false, result: "Implementar"}//await ApiLogin.signInByKey(resCurrentSession.result.segredo);

			if (resLoginbyKey.status)
			{
				const accessToken = resLoginbyKey.result.token;
				setSession(accessToken);

				const resNewSession = await ApiUser.getSession();

				dispatch(
				{
					type: 'LOGIN',
					payload: {
						user: resNewSession.result,
					},
				});
			}
		}
	};

	return (
		<AuthContext.Provider
			value={
			{
				...state,
				method: 'jwt',
				login,
				logout,
				register,
				session
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}