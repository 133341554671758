import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider, Drawer, List, Stack } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CrudNavItem from './CrudNavItem';

import { SkeletonMailNavItem } from 'src/components/skeleton';
import { NAV } from 'src/config';
import { useCrud } from 'src/context/CrudProvider/useCrud';
import { PATH_DASHBOARD } from 'src/routes/paths';

const CrudNav = ({ items, openNav, onOpenCompose, onCloseNav }) => 
{
	const { pathname } = useLocation();
	const { id } = useCrud();

	const isDesktop = useResponsive('up', 'md');
	const navigate = useNavigate();

	const isLoading = !items.length;

    useEffect(() => 
	{
		if (openNav) onCloseNav();
	}, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]);

    const handleOpenCompose = () =>  navigate(PATH_DASHBOARD[id]?.insert);

	const renderContent = (
		<>
			<Stack justifyContent="center" flexShrink={0} sx={{ px: 2.5, height: 80 }}>
				{
					PATH_DASHBOARD[id]?.insert &&
					<Button
						fullWidth
						color="inherit"
						variant="contained"
						startIcon={<Iconify icon="eva:edit-fill" />}
						onClick={handleOpenCompose}
						sx={{
							bgcolor: 'text.primary',
							color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
							'&:hover': {
								bgcolor: 'text.primary',
								color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
							},
						}}
					>
						Adicionar
					</Button>
				}
			</Stack>
			<Divider />
			<Scrollbar>
				<List disablePadding>
					{
						(isLoading ? [...Array(8)] : items).map((label, index) =>
							label 
							? <CrudNavItem key={label.id + "_" + index} label={label}  /> 
							: <SkeletonMailNavItem key={index} />
						)
					}
				</List>
			</Scrollbar>
		</>
	);

	return (
		<>
			{
                isDesktop 
                ? <Drawer
					variant="permanent"
					PaperProps={{
						sx: {
							width: NAV.W_BASE,
							position: 'relative',
						},
					}}
				>
					{renderContent}
				</Drawer>
				: <Drawer
					open={openNav}
					onClose={onCloseNav}
					ModalProps={{ keepMounted: true }}
					PaperProps={{
						sx: {
							width: NAV.W_BASE,
						},
					}}
				>
					{renderContent}
				</Drawer>
		    }
		</>
	);
}

CrudNav.propTypes = {
	items: PropTypes.array,
	openNav: PropTypes.bool,
	onCloseNav: PropTypes.func,
	onOpenCompose: PropTypes.func,
};


export default CrudNav