import React, { createContext, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import { useSnackbar } from 'src/components/snackbar';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { capitalize, fetchDataOn, toDecrypt, toEncrypt } from 'src/helpers/utils';
import { getFieldMainFromChild, getInitialValues, getSelects, parseTableName, parseValuesToSend } from 'src/components/Ca2/FormReusable/helper';

export const FormContext = createContext({});

export const FormProvider = ({idPage, title, table, fieldsInit, optionsButtonsList, children}) =>
{
    const params = useParams();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({});
    const [fields, setFields] = useState(fieldsInit);
    const [selectStatus, setSelectStatus] = useState({ status: false, msg: ''});
    const [validated, setValidated] = useState(false);

    const dataTable = async () =>
    {
        if (params.id)
        {
            const endpoint = `/${table}/get/${toDecrypt(params.id)}`;
		    return await fetchDataOn(endpoint, null, 'get');
        }
        else
            return null;
    }

    const dataCombo = async (table, body=null) =>
    {
        table = parseTableName(table);

        const endpoint = `/${table}/combo`;
		return await fetchDataOn(endpoint, body, body ? 'post' : 'get');
    }

    const query = useQuery([`${table}_edit`], async () => await dataTable(), { retry: 10, refetchIntervalInBackground: false });

    const defineInitialValues = async () =>
    {
        if (query.data)
        {
            const { status, result } = query.data;

            if (status)
            {   
                setValues(getInitialValues(fields, result));
                await setDataToFieldSelectMainInit(result);
            }
            else
                enqueueSnackbar(result, {variant: 'error'});
        }
        else
        {
            setValues({})
            await setDataToFieldSelectMainInit([]);
        }
    }

    const setDataToFieldSelectMainInit = async (vals) =>
    {
        setSelectStatus({ status: true, msg: "Iniciando..."});

        const selects = getSelects(fields);

        for await (const select of selects)
        {
            const { name, label } = select;
           
            const selectMain = getFieldMainFromChild(selects, select);

            var body = null;
            var msg = '';
       
            if (selectMain)
            {
                body = {[selectMain.name]: vals[selectMain.name] ? vals[selectMain.name] : 1};

                if (select.isUnique && params.id)
                    body[name] = vals[name]
                   
                msg = ` de ${selectMain.label}(s)`;
            }
    
            setSelectStatus({ status: true, msg: `Buscando itens de ${label}(s)${msg}`});

            try
            {
                const { status, result } = await dataCombo(name.replace('cod_',''), body);

                updateItemsFields(status, result, name);
            }
            catch (error)
            {
                updateItemsFields(true, [], name);
                enqueueSnackbar(`Não foi possível carregar os itens de ${label}. Motivo: ${error.message}`, {variant: 'error'});
            }
        }

        setSelectStatus({ status: false, msg: "Pronto"});
    }

    const updateItemsFields = (status, result, name) =>
    {
        if (status)
        {
            var newFields = fields;
            newFields[fields.findIndex(x => x.name === name)].items = result;

            setFields(newFields);
        }
    }

    useEffect(() =>
    {
        setValues({});
    }, 
    []);

    useEffect(() =>
    {
        if (params.id === undefined)
        {
            setValues({});
            document.getElementById(`form_${table}`).reset();
        }
    }, 
    // eslint-disable-next-line
    [params.id]);

    useEffect(() =>
    {
        defineVisibilityInit(values);
    }, 
    // eslint-disable-next-line
    [values]);

    useEffect(() =>
    {
        (async () => await defineInitialValues())();
    }, 
    // eslint-disable-next-line
    [query.data]);

    const onChange = (e) =>
    {
		const { value, id, name } = e.target;

        const ID = id ?? name;

        setValues({
            ...values,
            [ID]: value
        });

        defineVisibility(ID, value);

        (async () => await setDataToFieldSelectChild(ID, value))();
    }

    const defineVisibilityInit = (values) =>
    {
        Object.keys(values).forEach(name =>
        {
            const value = values[name];
            defineVisibility(name, value);
        });
    }

    const defineVisibility = (name, value) =>
    {
        const { items } = fields.find(field => field.name === name);
        
        if (items)
        {
            let fieldsNew = fields;

            const visibility = items.find(x => x.value === value);

            if (visibility)
            {
                visibility?.visible?.forEach(item => 
                {   
                    let fieldIndex = fields.findIndex(field => field.name === item.id);
     
                    if (item?.label)
                        fieldsNew[fieldIndex].label = item.label

                    if (fieldsNew[fieldIndex])
                    {
                        fieldsNew[fieldIndex].visible = item.status;    
                    } 
                });
    
                setFields(fieldsNew);
            }
        }
    }

    const setDataToFieldSelectChild = async (id, value) =>
    {
        const { type, name, children, label } = fields.find(field => field.name === id);
       
        if (type === 'int' && name.startsWith("cod_") && children)
        {
            setSelectStatus({ status: true, msg: `Preparando`});
            
            for await (const child of children)
            {
                const childObj = fields.find(field => field.name === child);
                const tableChild = child.replace("cod_", "");
                const body = { [name]: value }
       
                setSelectStatus({ status: true, msg: `Atualizando itens de ${label}(s)`});

                try
                {
                    const { status, result} = await dataCombo(tableChild, body);

                    updateItemsFields(status, result, child);
                }
                catch (error)
                {
                    updateItemsFields(true, [], child);

                    enqueueSnackbar(`Não foi possível carregar os itens de ${childObj.label} de ${label}. Motivo: ${error.message}`, { variant: 'error' });
                }
            }

            setSelectStatus({ status: false, msg: 'Pronto'});
        }
    }

    //
    // Send Data
    //
    const sendData = async () =>
    {
        if (params.id)
        {
            const endpoint = `/${table}/update/${toDecrypt(params.id)}`;
		    return await fetchDataOn(endpoint, parseValuesToSend(fields, values, params), "put");
        }
        else
        {
            const endpoint = `/${table}/add`;
		    return await fetchDataOn(endpoint, parseValuesToSend(fields, values, params), "post");
        }
    }

    const mutation = useMutation(sendData)

    const onSubmit = () =>
    {
        validation();
        onSend();
    }

    const validation = () =>
    {
        let errors = [];

        fields.filter(field => field.required && field.visible).forEach(field =>
        {
            const { name } = field;

            const isValid = !(values[name] === undefined || values[name]?.toString().trimStart().trimEnd().length === 0);

            if (!isValid)
                errors.push(field)
        });

        if (errors.length > 0)
        {
            const { name, label } = errors[0];

            enqueueSnackbar(`O campo ${label} é de preenchimento obrigatório !`, { variant: 'warning' });
  
            document.getElementById(name).focus();
        }

        return errors.length === 0;
    }

    const onSend = () =>
    {
        if (validation())
        {
            confirmAlert({
                title: 'Confirmação',
                message: `Tem certeza que deseja ${params.id ? "ATUALIZAR" : "CADASTRAR"} esses novos dados?`,
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => mutation.mutate(parseValuesToSend(fields, values, params))
                    },
                    { label: 'Não'}
                ]
            });
        }
    } 

    useEffect(() =>
    {
        const { data, isSuccess } = mutation;

        if (isSuccess)
        {
            const { status, result, id } = data;

            if (status)
            {
                enqueueSnackbar(result.replace("Item",capitalize(title)));

                if (!params.id)
                    navigate(PATH_DASHBOARD[idPage].edit.replace(":id",toEncrypt(id)));
                else
                    query.refetch();
            }
            else
                enqueueSnackbar(result, {variant: 'error'});
        }
    }, 
    // eslint-disable-next-line
    [mutation.isSuccess]);

    useEffect(() =>
    {
        const { isError, error } = mutation;

        if (isError)
        {
            const { status, result } = error.response.data;

            if (status)
                enqueueSnackbar(result, {variant: 'warning'});
            else
                enqueueSnackbar(`Ops, houve algum erro: ${result}`, {variant: 'error'});
        }
    }, 
    // eslint-disable-next-line
    [mutation.isError]);

    return (
        <FormContext.Provider value={
            {
                idPage,
                title,
                table,
                fields,
                optionsButtonsList,
                selectStatus,
                values,
                validated,

                query,
                mutation,

                setValidated,
                setFields,
                setValues,
                onChange,
                onSubmit,
            }}
        >
            {children}
        </FormContext.Provider>
    );
}