import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Stack } from '@mui/system';

import { TextArea, InputSelect, InputText, Item, IconButton } from 'src/components/Ca2';
import { getPermission, tranformType } from './helper';

import InputPassword from './InputPassword';

const Field = ({field, value, onChange, helper=''}) => 
{
    const { type, name, label, required, items, visible, accessFormMain } = field;

    const params = useParams();
    const navigate = useNavigate();

    const describe = tranformType(field);
    const disabled = !getPermission(params, field);

    const parseValue = (value, isNumber=false) => value !== undefined && value !== null ? value : (isNumber ? NaN : '');

    switch (describe.type)
    {
        case 'password':
            return visible
            ? <Grid 
                item 
                xs={field?.cfgCol?.xs ?? 12} 
                sm={field?.cfgCol?.sm ?? 12} 
                md={field?.cfgCol?.md ?? 12} 
                lg={field?.cfgCol?.lg ?? 12} 
                xl={field?.cfgCol?.xl ?? 12}    
                xxl={field?.cfgCol?.xxl ?? 12}
            >
                <InputPassword
                    key={name}
                    id={name}
                    label={label}
                    value={parseValue(value)}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    helperText={helper}
                />
            </Grid>
            : <input type="hidden" id={name} name={name} value={parseValue(value)} />
        case 'text':
            return visible
            ? <Grid 
                item 
                xs={field?.cfgCol?.xs ?? 12} 
                sm={field?.cfgCol?.sm ?? 12} 
                md={field?.cfgCol?.md ?? 12} 
                lg={field?.cfgCol?.lg ?? 12} 
                xl={field?.cfgCol?.xl ?? 12}    
                xxl={field?.cfgCol?.xxl ?? 12}
            >
                <InputText
                    key={name}
                    id={name}
                    label={label}
                    value={parseValue(value)}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    helper={helper}
                />
            </Grid>
            : <input type="hidden" id={name} name={name} value={parseValue(value)} />
        case 'number':
            return visible
            ? <Grid 
                item 
                xs={field?.cfgCol?.xs ?? 12} 
                sm={field?.cfgCol?.sm ?? 12} 
                md={field?.cfgCol?.md ?? 12} 
                lg={field?.cfgCol?.lg ?? 12} 
                xl={field?.cfgCol?.xl ?? 12}    
                xxl={field?.cfgCol?.xxl ?? 12}
            >
                <InputText
                    key={name}
                    id={name}
                    label={label}
                    type={"number"}
                    value={parseValue(value, true)}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    helper={helper}
                />
            </Grid>
            : <input type="hidden" id={name} name={name} value={parseValue(value, true)} />
        case 'time':
            return visible
            ? <Grid 
                item 
                xs={field?.cfgCol?.xs ?? 12} 
                sm={field?.cfgCol?.sm ?? 12} 
                md={field?.cfgCol?.md ?? 12} 
                lg={field?.cfgCol?.lg ?? 12} 
                xl={field?.cfgCol?.xl ?? 12}    
                xxl={field?.cfgCol?.xxl ?? 12}
            >
                <InputText
                    key={name}
                    id={name}
                    label={label}
                    type={"time"}
                    value={parseValue(value)}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    step={1}
                    helper={helper}
                />
            </Grid>
            : <input type="hidden" id={name} name={name} value={parseValue(value)} />
        case 'date':
            return visible
            ? <Grid 
                item 
                xs={field?.cfgCol?.xs ?? 12} 
                sm={field?.cfgCol?.sm ?? 12} 
                md={field?.cfgCol?.md ?? 12} 
                lg={field?.cfgCol?.lg ?? 12} 
                xl={field?.cfgCol?.xl ?? 12}    
                xxl={field?.cfgCol?.xxl ?? 12}
            >

                <InputText
                    key={name}
                    id={name}
                    label={label}
                    type={"date"}
                    value={parseValue(value)}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    step={1}
                    helper={helper}
                />
            </Grid>
            : <input type="hidden" id={name} name={name} value={parseValue(value)} />
        case 'select-api':
            return visible
            ? <Grid 
                item 
                xs={field?.cfgCol?.xs ?? 12} 
                sm={field?.cfgCol?.sm ?? 12} 
                md={field?.cfgCol?.md ?? 12} 
                lg={field?.cfgCol?.lg ?? 12} 
                xl={field?.cfgCol?.xl ?? 12}    
                xxl={field?.cfgCol?.xxl ?? 12}
            >
                <Stack flexDirection={"row"} alignItems={"center"}>
                    <InputSelect
                        key={name}
                        id={name}
                        label={label}
                        items={items}
                        value={parseValue(value)}
                        onChange={onChange}
                        required={required}
                        disabled={disabled}
                        style={{width: '100%'}}
                        helper={helper}
                    />
                    {
                        accessFormMain?.insert &&  
                        <IconButton 
                            icon="material-symbols:add-box-outline" 
                            title="Adicionar"
                            onClick={() =>
                            {
                                navigate(accessFormMain?.insert)
                            }}
                        />
                    }
                    {
                        accessFormMain?.edit && value !== "" &&
                        <IconButton 
                            icon="material-symbols:edit-document-outline" 
                            title="Editar"
                            onClick={() =>
                            {
                                navigate(accessFormMain?.edit.replace(':id',parseValue(value)))
                            }}
                        />
                    }
                </Stack>
            </Grid>
            : <input type="hidden" id={name} name={name} value={parseValue(value)} />
        case 'enum':
            return (
                <Grid 
                    item 
                    xs={field?.cfgCol?.xs ?? 12} 
                    sm={field?.cfgCol?.sm ?? 12} 
                    md={field?.cfgCol?.md ?? 12} 
                    lg={field?.cfgCol?.lg ?? 12} 
                    xl={field?.cfgCol?.xl ?? 12}    
                    xxl={field?.cfgCol?.xxl ?? 12}
                >
                    <InputSelect
                        key={name}
                        id={name}
                        label={label}
                        items={describe.items || []}
                        value={parseValue(value)}
                        onChange={onChange}
                        required={required}
                        disabled={disabled}
                        helper={helper}
                    />
                </Grid>
            )
        case 'text-area':
            return visible
            ? <Grid 
                item 
                xs={field?.cfgCol?.xs ?? 12} 
                sm={field?.cfgCol?.sm ?? 12} 
                md={field?.cfgCol?.md ?? 12} 
                lg={field?.cfgCol?.lg ?? 12} 
                xl={field?.cfgCol?.xl ?? 12}    
                xxl={field?.cfgCol?.xxl ?? 12}
            >
                <TextArea
                    key={name}
                    id={name}
                    label={label}
                    value={parseValue(value)}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    helper={helper}
                />
            </Grid>
            : <input type="hidden" id={name} name={name} value={parseValue(value)} />
        default:
            return <Grid 
            item 
            xs={field?.cfgCol?.xs ?? 12} 
            sm={field?.cfgCol?.sm ?? 12} 
            md={field?.cfgCol?.md ?? 12} 
            lg={field?.cfgCol?.lg ?? 12} 
            xl={field?.cfgCol?.xl ?? 12}    
            xxl={field?.cfgCol?.xxl ?? 12}
        >
            <Item key={name} label={label} type={type} />
        </Grid>
    }
}

export default Field