import React from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

import { IconButton } from '..';
import Iconify from 'src/components/iconify/Iconify';

const InputPassword = ({
    id,
    label,
    placeholder,
    text,
    style,
    value,
    error,
    maxLength,
    disabled,
    required=false,
    step,
    others,
    onChange=()=>null

}) => 
{
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <OutlinedInput

                id={id}
                name={id}
                label={label}
                placeholder={placeholder}
                onChange={onChange}
                style={style}
                value={value === null ? '' : value}
                disabled={disabled}
                required={required}
                fullWidth
                error={error}
                {...others}

                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Iconify icon="material-symbols:visibility-off" /> : <Iconify icon="material-symbols:visibility" />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>

    );
}

export default InputPassword