import { APP_ALIAS, APP_NAME } from "src/config/constants";

export const list = (company) =>
{
	company = company.trim().toLowerCase();

	switch (company) 
	{
		case 'localhost': 			return { applicationName: APP_NAME, logo: 'dashboard',name: "Dev - Local", host: "http://localhost:4000" };
		case 'devl': 				return { applicationName: APP_NAME, logo: 'dashboard',name: "Dev - Local", host: "http://localhost:4000" };
        case 'dev': 				return { applicationName: APP_NAME, logo: 'dashboard',name: "Dev - Remoto", host: "http://www.ca2soft.com.br:4000" };
		case 'devssl': 				return { applicationName: APP_NAME, logo: 'dashboard',name: "Dev - Remoto com SSL", host: "https://www.ca2soft.com.br:4001" };
        case 'prod': 				return { applicationName: APP_NAME, logo: 'dashboard',name: "Produção", host: "http://cashback.renathais.com.br:4000" };
		case 'prodssl': 				return { applicationName: APP_NAME, logo: 'dashboard',name: "Produção com SSL", host: "https://cashback.renathais.com.br:4001" };
		default:					return { applicationName: APP_NAME, logo: 'dashboard',name: "", host: "http://invalido" };
	}
}

export const getLogoName = (manager) => list(manager.trim().toLowerCase())?.logo;

export const getApplicationName= (manager) => list(manager.trim().toLowerCase())?.applicationName;

export const getClientName = () => 
{
	const name = localStorage.getItem(APP_ALIAS + '-client-name');

	return name ? name : '';
}

export const setClientName = (manager) => 
{
	const name = list(manager.trim().toLowerCase())?.name;
	localStorage.setItem(APP_ALIAS + '-client-name', name);
}
