import { Helmet } from 'react-helmet-async';
import { APP_NAME } from 'src/config/constants';
import { getClientName } from 'src/helpers/servers';
import { version } from 'src/helpers/utils';
import Login from '../sections/auth/Login';

// import Login from '../../sections/auth/LoginAuth0';

export default function LoginPage() 
{
	return (
		<>
			<Helmet>
				<title> Login | {APP_NAME} v{version()} | {getClientName()}</title>
			</Helmet>
			<Login />
		</>
	);
}
