import React from 'react';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import useResponsive from 'src/hooks/useResponsive';

import { toEncrypt } from 'src/helpers/utils';
import { IconButton, MenuButton } from 'src/components/Ca2';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useCrud } from 'src/context/CrudProvider/useCrud';

const Toolbar = ({onOpenNav, ...other}) => 
{
    const navigate = useNavigate();

    const { 
        id,
        refetch, 
        selectedRow,
        cod_pk_name ,
        optionsButtonsList
    } = useCrud();

    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');

    const getNameSelected = () =>
    {
        if (selectedRow)
        {
            const idList = [
                {
                    name: 'vehicle',
                    colName: 'plate'
                },
                {
                    name: 'tracker',
                    colName: 'serial_number'
                },
            ];
            
            let colSelected = idList.find(x => x.name === id);

            return colSelected ? selectedRow.row[colSelected.colName].toUpperCase() : ''
        }
        else 
            return '';
    }

    return (
        <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            flexShrink={0}
            sx={{
                px: 2,
                height: 80,
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
            {...other}
        >
            <Stack direction="row" alignItems="center" flexGrow={1}>
                <IconButton 
                    title="Menu" 
                    icon="eva:menu-fill" 
                    isVisible={!mdUp} 
                    onClick={onOpenNav}
                /> 
                <IconButton 
                    title="Atualizar Dados" 
                    icon="eva:refresh-fill" 
                    isVisible={smUp} 
                    onClick={() => refetch()} 
                />
                {
                    selectedRow &&
                    <>
                        <IconButton 
                            title={`Editar ${getNameSelected()}`} 
                            icon="akar-icons:edit" 
                            onClick={
                                () => 
                                {
                                    const path = PATH_DASHBOARD[id].edit.replace(':id', toEncrypt(selectedRow.row[cod_pk_name].toString())) 
                                    navigate(path);
                                }
                            }
                        />
                        {
                            optionsButtonsList.length > 0 &&
                            <MenuButton
                                id="more"
                                title="Mais"
                                icon={"eva:more-vertical-fill"}
                                items={
                                    optionsButtonsList.map(item =>
                                    {
                                        const { title, path, cod_pk, type, color } = item;
                                        return {
                                            title: title,
                                            type: type ?? 'menu',
                                            color: color,
                                            onClick: () => navigate(path.replace(':id', toEncrypt(selectedRow.row[cod_pk].toString())))
                                        }
                                    })
                                }
                            />
                        }
                    </>
                }
            </Stack>
            <GridToolbarQuickFilter
                variant="outlined"
                size="small"
                placeholder="Pesquisar"
            />
        </Stack>
    )
}

export default Toolbar