import React from 'react';
import { Box, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { useForm } from 'src/context/FormReusable/useForm';
import { datetime2br, toDecrypt, toEncrypt } from 'src/helpers/utils';
import { MenuButton } from '..';

import Fields from './Fields';
import Footer from './Footer';

const Form = () => 
{
    const { title, table, query, optionsButtonsList } = useForm();

    const params = useParams();
    const navigate = useNavigate();
    const Info = () =>
    (
        params.id && query.data && query.data.status 
        ? <Grid item xs={12} md={4}>
            <Card sx={{ pt: 5, pb: 5, px: 3, mb: 3 }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Cadastrado por
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                    {query.data?.result?.ins_usuario}
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Data do Cadastro
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {datetime2br(query.data?.result?.ins_data)}
                </Typography>
            </Card>
            <Card sx={{ pt: 5, pb: 5, px: 3 }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Modificado por
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                    {query.data?.result?.upd_usuario}
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Data da Modificação
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {datetime2br(query.data?.result?.upd_data)}
                </Typography>
            </Card>
        </Grid>
        : <Grid item md={2}></Grid>
    )

    return (
    <Grid container spacing={3}>
        { !params.id && <Grid item md={2}></Grid>}
        <Grid item xs={12} md={8}>
            <Card sx={{  }}>
                <CardHeader
                    title={title + " - " + (params.id ? `ID: ${toDecrypt(params.id).padStart(6,'0')}` : "Cadastro")}
                    action={
                        params.id && optionsButtonsList.length > 0 &&
                        <MenuButton
                            id="more"
                            title="Opções"
                            icon={"eva:more-vertical-fill"}
                            items={
                                optionsButtonsList.map(item =>
                                {
                                    const { title, path, type, color } = item;
                                    return {
                                        title: title,
                                        type: type ?? 'menu',
                                        color: color,
                                        onClick: () => navigate(path.replace(':id', toEncrypt(params.id.toString())))
                                    }
                                })
                            }
                        />
                    }
                />
                <Divider sx={{pt: 1, pb: 1}}/>
                <form id={`form_${table}`} style={{margin: 20}}>
                    <Box
                        rowGap={3}
                        columnGap={1}
                        display="grid"
                    >
                        <Fields />
                    </Box>
                    <Footer />
                </form>
            </Card>
        </Grid>
        <Info />
    </Grid>
    )
}

export default Form