import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useForm } from 'src/context/FormReusable/useForm';

const Footer = () =>
{
    const params = useParams();

    // const queryClient = useQueryClient();
    const { query, mutation, selectStatus, onSubmit } = useForm(); //table, setValues, setValidated,

    // const redefineForm = () =>
    // {
    //     queryClient.removeQueries([`${table}_edit`]);

    //     document.getElementById(`form_${table}`).reset();
    //     setValues({});
    //     setValidated(false);
    // }

    // const onClick = () =>
    // {
    //     redefineForm();
    //     //navigate(`${Util.getToFromNav(table)}/insert`);
    // }

    return !query.isLoading &&
    !query.isFetching &&
    !mutation.isLoading &&
    // !mutation.isError &&
    // !mutation.isSuccess &&
    !selectStatus.status &&
    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton 
            type="button" 
            variant="contained" 
            loading={false}
            onClick={() => onSubmit()}
        >
            {!params.id ? 'Adicionar' : 'Salvar Alterações'}
        </LoadingButton>
    </Stack>
}

export default Footer