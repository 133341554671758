import React, { useState } from 'react';
import { Container, Card, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useCrud } from 'src/context/CrudProvider/useCrud';
import { IconButton } from '..';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import CrudNav from './CrudNav';
import CrudList from './CrudList';

const Main = () => 
{
	const navigate = useNavigate();

    const { id, title, navItemsFilter } = useCrud();

    const { themeStretch } = useSettingsContext();

    const [openNav, setOpenNav] = useState(false);
	const [/*openCompose, */setOpenCompose] = useState(false);

    const handleOpenCompose = () => setOpenCompose(true);

    const handleCloseNav = () => setOpenNav(false);

	const handleOpenNav = () => setOpenNav(true);

    return (
        <Container maxWidth={themeStretch ? false : 'xl'}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
				<CustomBreadcrumbs
					heading={title}
					links={[  
						{
							name: 'Dashboard',
							href: PATH_DASHBOARD.root,
						},
						{ 
							name: title,
							href: PATH_DASHBOARD[id]?.list,
						},
					]}
				/>
				<IconButton icon="ri:arrow-go-back-fill" title="Voltar à tela anterior" onClick={() => navigate(-1)} />
			</div>
            <Card
				sx={{
					height: '70vh',
					display: 'flex',
				}}
			>
                <CrudNav 
					items={navItemsFilter} 
					openNav={openNav} 
					onCloseNav={handleCloseNav} 
					onOpenCompose={handleOpenCompose} 
				/>
                <Stack flexGrow={1}>
                    <CrudList onOpenNav={handleOpenNav}/>
                </Stack>
            </Card>
        </Container>
    )
}

export default Main