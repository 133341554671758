import React from 'react';
import { TextField } from '@mui/material';

const TextArea = ({
    id,
    type,
    label,
    placeholder,
    text,
    style,
    rows=5,
    value,
    error,
    maxLength,
    disabled,
    required=false,
    step,
    onChange=()=>null,
    ...others

}) => 
{
    // const [invalid, setInvalid] = useState(false);
    // const [valid, setValid] = useState(false);

    // useEffect(() =>
    // {
    //     if (required)
    //         validate(value);
    // }, 
    // // eslint-disable-next-line
    // [value]);

    // const validate = (value) =>
    // {
    //     const val = value ? value.toString() : '';
    //     setInvalid(val.trim().length === 0);
    //     setValid(val.trim().length > 0);
    // }

    return (
        <TextField
            variant="outlined"
            type={type}
            id={id}
            name={id}
            label={label}
            placeholder={placeholder}
            onChange={(e) =>
            {
                // if (required)
                // {
                //     const { value } = e.target;
                //     validate(value);
                // }

                onChange(e)
            }}
            style={style}
            defaultValue={value === null ? '' : value}
            disabled={disabled}
            required={required}
            fullWidth
            error={error}
            //helperText={error && `Preenchimento obrigatório.`}
			multiline
			rows={rows}
            {...others}
        />

    );
}

export default TextArea