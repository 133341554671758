import React from 'react';
import { TextField } from '@mui/material';

const InputText = ({
    id,
    type,
    label,
    placeholder,
    text,
    style,
    value,
    error,
    maxLength,
    disabled,
    required=false,
    step,
    others,
    onChange=()=>null,
    helper

}) => 
{
    return (
        <TextField
            variant="outlined"
            type={type}
            id={id}
            name={id}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            style={style}
            value={value === null ? '' : value}
            disabled={disabled}
            required={required}
            fullWidth
            error={error}
            {...others}
            helperText={helper}
        />

    );
}

export default InputText