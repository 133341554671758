import React from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { useForm } from 'src/context/FormReusable/useForm';

const LoadingMutation = () =>
{
    const params = useParams();
    const { mutation } = useForm();

    return mutation.isLoading && (
        <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
            <CircularProgress />
            <span style={{margin: 10}}>{params.id ? 'Atualizando' : 'Enviando'} dados...</span>
        </div>
    )
}

export default LoadingMutation