import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';
import { groupBy } from 'src/helpers/utils';


const initialState = {
	isLoading: false,
	error: null,
	contacts: { byId: {}, allIds: [] },
	conversations: { byId: {}, allIds: [] },
	activeConversationId: null,
	participants: [],
	recipients: [],
};

const slice = createSlice(
{
	name: 'chat',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) 
		{	
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) 
		{
			state.isLoading = false;
			state.error = action.payload; 
		},

		// GET CONTACT SSUCCESS
		getContactsSuccess(state, action) 
		{
			const contacts = action.payload;

			state.contacts.byId = keyBy(contacts, 'id');
			state.contacts.allIds = Object.keys(state.contacts.byId);
		},

		// GET CONVERSATIONS
		getConversationsSuccess(state, action) 
		{
			const conversations = action.payload;

			state.conversations.byId = keyBy(conversations, 'id');
			state.conversations.allIds = Object.keys(state.conversations.byId);
		},

		// GET CONVERSATION
		getConversationSuccess(state, action) 
		{
			const conversation = action.payload;
			
			if (conversation) 
			{
				state.conversations.byId[conversation.id] = conversation;
				state.activeConversationId = conversation.id;
				if (!state.conversations.allIds.includes(conversation.id)) {
					state.conversations.allIds.push(conversation.id);
				}
			} 
			else 
			{
				state.activeConversationId = null;
			}
		},

    // ON SEND MESSAGE
    sendMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, sendMessage, resetActiveConversation } = slice.actions;

export function getContacts() 
{
	return async () => 
	{
		dispatch(slice.actions.startLoading());

		try 
		{
			// const response = await axios.get('/api/chat/contacts');
			const { status, result } = { status: false, result: null } //await ApiVehicles.comboByGroup();

			if (status)
			{
				dispatch(slice.actions.getContactsSuccess(result.map(item => 
				{
					return {
						address: '',
						avatar: "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
						email: '',
						id: +item.id,
						lastActivity: "2022-11-08T17:41:46.433Z",
						name: item.label,
						phone: '',
						role: 'vehicle',
						status: 'online',
						username: item.label
					}
				})));
			}
			else 
			{
				dispatch(slice.actions.hasError(result));
			}
		} 
		catch (error) 
		{
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getConversations() 
{
	return async () => 
	{
		dispatch(slice.actions.startLoading());

		try 
		{
			const { status, result } = { status: false, result: null } //await ApiMessages.getRows();

			if (status)
			{
				const groupByRastreador = groupBy(result,'cod_rastreador')

				const conversations = groupByRastreador.map(item => 
				{
					const newItem = {
						id: +item.group,
						messages: item.items.map(message => 
						{
							const newMessage = 
							{
								id: message.cod_mensagem,
								attachments: [],
								body: message.texto,
								contentType: 'text',
								createdAt: message.ins_data,
								senderId: message.cod_rastreador
							}

							return newMessage;
						}),
						participants: [
							{
								avatar: "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_15.jpg",
								id: +item.group,
								name: item.items[0].placa,
								username: item.items[0].placa
							}
						],
						type: 'ONE_TO_ONE',
						unreadCount: item.items.length
					}

					return newItem;
				});
	
				dispatch(slice.actions.getConversationsSuccess(conversations));
			}
			else 
			{
				dispatch(slice.actions.hasError(result));
			}
		} 
		catch (error) 
		{
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getConversation(conversationKey) 
{
	return async () => 
	{
		dispatch(slice.actions.startLoading());

		try 
		{
			const { status, result } = { status: false, result: null } //await ApiMessages.getRowsPerRastreador(conversationKey);

			if (status)
			{
				const conversation = {
					id: +conversationKey,
					messages: result.map(item => 
					{
						const newItem = {
							id: +item.cod_mensagem,
							attachments: [],
							body: item.texto,
							contentType: 'text',
							createdAt: item.ins_data,
							senderId: +item.cod_mensagem
						};

						return newItem;
					})
				}
				
				dispatch(slice.actions.getConversationSuccess(conversation));

				dispatch(slice.actions.getParticipantsSuccess([
					{
						address: '',
						avatar: "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
						email:"milo.farrell@hotmail.com",
						id: +result[0].cod_mensagem,
						lastActivity: result[result.length - 1].ins_data,
						name: result[0].placa,
						phone: '',
						role: 'vehicle',
						status: 'online',
						username: result[0].placa
					}
				]));
			}
			else 
				dispatch(slice.actions.hasError(result));

			// const response = await axios.get('/api/chat/conversation', 
			// {
			// 	params: { conversationKey },
			// });
			
			// dispatch(slice.actions.getConversationSuccess(response.data.conversation));
		} 
		catch (error) 
		{
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function markConversationAsRead(conversationId) 
{
	console.log("markConversationAsRead", conversationId)
	return async () => 
	{
		dispatch(slice.actions.startLoading());

		// try 
		// {
		// 	await axios.get('/api/chat/conversation/mark-as-seen', {
		// 		params: { conversationId },
		// 	});

		// 	dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
		// } 
		// catch (error) 
		// {
		// 	dispatch(slice.actions.hasError(error));
		// }
	};
}

export function getParticipants(conversationKey) 
{
	console.log("getParticipants", conversationKey)
	return async () => 
	{
		dispatch(slice.actions.startLoading());

		try 
		{
			// const response = await axios.get('/api/chat/participants', 
			// {
			// 	params: { conversationKey },
			// });


			
			//dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
		} 
		catch (error) 
		{
			dispatch(slice.actions.hasError(error));
		}
	};
}
