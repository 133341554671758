import { useState } from 'react';
import { Stack, Typography } from '@mui/material';

import { APP_NAME } from 'src/config/constants';
import { version } from 'src/helpers/utils';

import LoginLayout from '../../layouts/login';
import AuthLoginForm from './AuthLoginForm';

// import AuthWithSocial from './AuthWithSocial';

export default function Login() 
{
	const [logoName, setLogoName] = useState('dashboard');

	return (
		<LoginLayout logoName={logoName}>
			<Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
				<Typography variant="h4">Acessar {APP_NAME}</Typography>

				{/* <Stack direction="row" spacing={0.5}>
					<Typography variant="body2">New user?</Typography>

					<Link variant="subtitle2">Create an account</Link>
				</Stack> */}

				{/* <Tooltip title={method} placement="left">
					<Box
						component="img"
						alt={method}
						src={APP_PATH_MAIN + `/assets/icons/auth/ic_${method}.png`}
						sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
					/>
				</Tooltip> */}
			</Stack>

			{/* <Alert severity="info" sx={{ mb: 3 }}>
				Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
			</Alert> */}

			<AuthLoginForm setLogoName={setLogoName}/>
			
			<Stack flexDirection={"row"} justifyContent={"center"} alignContent={"center"} sx={{pt: 5}}>
				<Typography variant="subtitle2" paragraph>
					{`${APP_NAME} v.${version()} - Developed by Ca2soft (${new Date().getFullYear()})`}
				</Typography>
			</Stack>

			{/* <AuthWithSocial /> */}
		</LoginLayout>
  );
}
