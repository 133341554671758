import React from 'react';
import { CircularProgress } from '@mui/material';

import { useForm } from 'src/context/FormReusable/useForm';

const LoadingSelect = () =>
{
    const { selectStatus } = useForm();

    return selectStatus.status &&
    <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
        <CircularProgress />
        <span style={{margin: 10}}>{selectStatus.msg}...</span>
    </div>
}

export default LoadingSelect