import React from 'react';
import PropType from 'prop-types';
import { Helmet } from 'react-helmet-async';

import { CrudProvider } from 'src/context/CrudProvider';
import Main from './Main';

import { APP_NAME } from 'src/config/constants';
import { getClientName } from 'src/helpers/servers';

const Index = ({ title, id, endpoint, columns, cod_pk_name, optionsButtonsList=[] }) => 
{
    return (
        <CrudProvider 
            id={id} 
            columns={columns} 
            endpoint={endpoint} 
            cod_pk_name={cod_pk_name} 
            title={title} 
            optionsButtonsList={optionsButtonsList}
        >
            <Helmet>
                <title>{title} | {APP_NAME} | {getClientName()}</title>
            </Helmet>
            <Main />
        </CrudProvider>
    )
}

Index.PropType = {
    title: PropType.string,
	id: PropType.string,
	endpoint: PropType.string,
	columns: PropType.array,
    cod_pk_name: PropType.string,
    optionsButtonsList: PropType.array
};


export default Index