import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Stack, FormControlLabel, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAuthContext } from 'src/auth/useAuthContext';
import { InputText } from 'src/components/Ca2';
import { APP_ALIAS } from 'src/config/constants';
import { getLogoName, setClientName } from 'src/helpers/servers';
import { getError, urlBase } from 'src/helpers/utils';

import LoadingScreen from 'src/components/loading-screen/LoadingScreen';

export default function AuthLoginForm({setLogoName}) 
{
  	const { login } = useAuthContext();
	const { enqueueSnackbar } = useSnackbar();

	const defaultValues = {
		manager: '',
		email: '',
		password: ''
	};

	const [statusData, setStatusData] = useState({ isLoading: false, message: '' });
	const [values, setValues] = useState(defaultValues);
	const [remember, setRemember] = useState(false);
	const [customized, setCustomized] = useState({ type: 'text', value: '' })

	useEffect(() =>
	{
		const rememberTemp = localStorage.getItem(APP_ALIAS + '-remember');

		if (rememberTemp)
		{
			const { email, manager } = JSON.parse(rememberTemp);
			setValues({...values, email, manager });
			setRemember(true);
			
			setLogoName(getLogoName(manager));
			setClientName(manager)
		}

		getCustomized();
	}, 
	// eslint-disable-next-line
	[]);

	useEffect(() =>
	{
		if (remember)
			localStorage.setItem(APP_ALIAS + '-remember', JSON.stringify({ email: values.email, manager: values.manager }));
		else
			localStorage.removeItem(APP_ALIAS + '-remember');
	}, 
	// eslint-disable-next-line
	[remember]);

	const getCustomized = () =>
	{
		if (!urlBase().includes('localhost'))
		{
			const val = "prodssl";
			setCustomized({type: 'hidden'})
			setValues({...values, manager: val})
			setLogoName(getLogoName(val));
			setClientName(val)
		}
	}

	const reset = (clearAll = false) => 
	{
		try 
		{
			if (clearAll) 
				setValues(defaultValues)
			else 
				setValues(...{password: ''})
		}
		catch
		{

		}
	};

	const onSubmit = async (data) => 
	{
		setStatusData({ isLoading: true, message: 'Enviando dados...' });

		try 
		{
			await login(data.manager, data.email, data.password);
		} 
		catch (error) 
		{
			const { result, response } = getError(error);

			if (response?.data?.result)
				enqueueSnackbar(response.data.result, { variant: 'warning'});
			else
				enqueueSnackbar(result, { variant: 'error'});

			setStatusData({ isLoading: false, message: '' });
			reset();
		}
	};

	const handleSubmit = () =>
	{
		if (values.manager.trim().length === 0)
		{
			enqueueSnackbar("Informe o Gestor", { variant: 'warning' });
			document.getElementById("manager").focus();
		}
		else 
		if (values.email.trim().length === 0)
		{
			enqueueSnackbar("Informe seu e-mail", { variant: 'warning' });
			document.getElementById("email").focus();
		}
		else
		if (values.password.trim().length === 0)
		{
			enqueueSnackbar("Informe sua Senha", { variant: 'warning' });
			document.getElementById("password").focus();
		}
		else
			onSubmit(values);
	}

	const onChange = (e) =>
	{
		const { name, value } = e.target;

		if (name === "manager")
		{
			setLogoName(getLogoName(value));
			setClientName(value)
		}
			
		setValues({...values, [name]: value});

		if (name !== 'password')
			setRemember(false);
	}

	return (
		statusData.isLoading 
		? <LoadingScreen />
		: <form>
			<Stack spacing={3}>
				{
					customized.type === "text" 
					? <InputText
						id="manager"
						label="Gestor"
						value={values.manager}
						onChange={onChange}
					/>
					: <input
						id="manager"
						type="hidden"
						label="Gestor"
						value={values.manager}
						onChange={onChange}
					/>
				}
				<InputText
					id="email"
					label="E-mail"
					value={values.email}
					onChange={onChange}
				/>
				<InputText
					id="password"
					label="Senha"
					type="password"
					value={values.password}
					onChange={onChange}
				/>
			</Stack>
			<Stack alignItems="flex-end" sx={{ my: 2 }}>
				<FormControlLabel
					control={
						<Switch 
							checked={remember} 
							id="remember" 
							name="remember"
							onChange={(e) => setRemember(e.target.checked)}
						/>
					}
					label="Lembrar"
				/>
			</Stack>
			<LoadingButton
				fullWidth
				color="inherit"
				size="large"
				type="button"
				variant="contained"
				loading={statusData.loading}
				sx={
				{
					bgcolor: 'text.primary',
					color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
					'&:hover': 
					{
						bgcolor: 'text.primary',
						color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
					},
				}}
				onClick={() => handleSubmit()}
			>
				Entrar
			</LoadingButton>
		</form>
  	);
}
