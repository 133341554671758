import React from 'react';
import Select from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem } from '@mui/material';

const InputSelect = (
{
    id,
    label,
    value,
    items=[],
    error,
    size="", //small
    onChange,
    disabled=false,
    required=false,
    style,
    helper,
}) =>
{
    const translateLabel = (label) =>
    {
        const toRemove = ['S', 'N'];
        const toAdd = ['Sim', 'Não'];

        const index = toRemove.findIndex(x => x === label);

        return index === -1 ? label : toAdd[index];
    }

    return (
        <div style={style}>
            <FormControl fullWidth>
                <InputLabel id={`select_label_${id}`}>{label}</InputLabel>
                <Select
                    name={id}
                    labelId={`select_label_${id}`}
                    id={id}
                    value={value !== undefined && value !== null ? value : ''}
                    disabled={disabled}
                    placeholder={label}
                    label={label}
                    size={size}
                    onChange={onChange}
                    required={required}
                    fullWidth
                    error={error}
                    // helperText={helper}
                >
                    <MenuItem value="" key={"select_" + id}> - Selecione um Item - </MenuItem>
                    {
                        items.map((item, key) =>
                        {
                            const val = item.value === undefined ? item.id : item.value;
                            return (
                                <MenuItem 
                                    key={"select_" + id + "_" + key}
                                    value={val}
                                >
                                    {
                                        item.label === undefined
                                        ? translateLabel(val)
                                        : translateLabel(item.label)
                                    }
                                </MenuItem >
                            )
                        })
                    }
                </Select>
            </FormControl>
        </div>
    
    );
}

export default InputSelect;