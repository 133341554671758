import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Stack, Drawer } from '@mui/material';

import { NAV } from '../../../config';
import { NavSectionVertical } from '../../../components/nav-section';

import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavAccount from './NavAccount';
import useResponsive from '../../../hooks/useResponsive';
import Update from '../header/Update';
import navConfig from './config';

NavVertical.propTypes = {
	openNav: PropTypes.bool,
	onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) 
{
	const { pathname } = useLocation();

	const isDesktop = useResponsive('up', 'lg');

	useEffect(() => 
	{
		if (openNav) 
			onCloseNav();
	}, 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[pathname]);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				'& .simplebar-content': {
					height: 1,
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		>
			<Stack
				spacing={3}
				sx={{
					pt: 3,
					pb: 2,
					px: 2.5,
					flexShrink: 0,
				}} 
			>
				<Logo />
				<NavAccount />
			</Stack>
			<NavSectionVertical data={navConfig} /> 
			{/* <NavSectionVertical data={menu} />  */}
			<Box sx={{ flexGrow: 1 }} />
			{/* <NavDocs /> */}
		</Scrollbar>
	);

	return (
		<Box
			component="nav"
			sx={{
				flexShrink: { lg: 0 },
				width: { lg: NAV.W_DASHBOARD },
			}}
		>
		{
			isDesktop 
			? <Drawer
					open
					variant="permanent"
					PaperProps={{
						sx: {
							width: NAV.W_DASHBOARD,
							bgcolor: 'transparent',
							borderRightStyle: 'dashed',
						},
					}}
				>
					{renderContent}
					<Update />
				</Drawer>
			: <Drawer
				open={openNav}
				onClose={onCloseNav}
				ModalProps={{
					keepMounted: true,
				}}
				PaperProps={{
					sx: {
						width: NAV.W_DASHBOARD,
					},
				}}
			>
				{renderContent}
				<Update />
			</Drawer>
		}
		</Box>
	);
}
