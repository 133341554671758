import PropTypes from 'prop-types';
import { Typography, ListItemText, ListItemButton } from '@mui/material';

import useActiveLink from 'src/hooks/useActiveLink';
import Iconify from 'src/components/iconify';

import { ICON } from 'src/config';
import { useCrud } from 'src/context/CrudProvider/useCrud';
import { useSnackbar } from 'src/components/snackbar';

CrudNavItem.propTypes = {
	label: PropTypes.object,
};

export default function CrudNavItem({ label, ...other }) 
{
	const { enqueueSnackbar } = useSnackbar();
	const { setFiltered, data, refetch, setSelectedRow } = useCrud();

	const { active } = useActiveLink(label.id);

	const handleClick = () => 
	{
		setSelectedRow(null);

		if (data)
		{
			const { status, result } = data;
			
			if (status)
			{
				if (label.origin)
				{
					var msg = `Filtro por ${label.name}`;

					const { id, type, values, message, isWarning } = label.origin;
					const valueSelected = values.find(item => item.value === label.name);
					
					if (type === "groupBy")
						setFiltered(result.filter(x => x[id] === valueSelected.value));
					else 
					if (type === "sum")
					{
						var resultTemp = [];
	
						values.forEach((item, i) => 
						{
							if (item.operator === "!==")
                            {
                                resultTemp = (i === 0) 
                                ? result.filter(x => x[item.col] !== item.value)
                                : resultTemp.filter(x => x[item.col] !== item.value);
                            }
                            else 
                            {
                                resultTemp = (i === 0) 
                                ? result.filter(x => x[item.col] === item.value)
                                : resultTemp.filter(x => x[item.col] === item.value);
                            }
						});
	
						setFiltered(resultTemp);

						if (message)
							msg = message;
					}

					enqueueSnackbar(msg, { variant: isWarning ? 'warning' : 'success'});
				}
				else 
					updateData();
			}
		}
	};

	const isUnread = label.unreadCount !== 0;

	const updateData = () =>
    {
        if (setFiltered)
            setFiltered(null);

        //setKeyWord('');
        refetch();
    }

	return label.count > 0 && (
		<ListItemButton
			onClick={handleClick}
			sx={{
				px: 3,
				height: 48,
				typography: 'body2',
				color: 'text.secondary',
				textTransform: 'capitalize',
				...(active && {
					color: 'text.primary',
					bgcolor: 'action.selected',
					fontWeight: 'fontWeightMedium',
				}),
			}}
			{...other}
		>
			<Iconify
				icon={label.icon}
				sx={{
					mr: 2,
					width: ICON.NAV_ITEM,
					height: ICON.NAV_ITEM,
					color: label.color,
				}}
			/>

			<ListItemText disableTypography primary={label.name} />

			{isUnread && <Typography variant="caption">{label.count}</Typography>}
		</ListItemButton>
	);
}