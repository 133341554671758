import React, { useEffect } from 'react';
import { Stack } from '@mui/material';

import { DataGrid } from 'src/components/Ca2';
import { useCrud } from 'src/context/CrudProvider/useCrud';

import Toolbar from './Toolbar';
import useResponsive from 'src/hooks/useResponsive';

const CrudList = ({onOpenNav}) => 
{
    const { 
		columns,
        data, isFetching,
        setSelectedRow, filtered
	} = useCrud();

    const isDesktop = useResponsive('up', 'md');

    useEffect(() =>
    {
        if (isDesktop)
            document.body.style.overflow = 'hidden';
    }, 
    [isDesktop]);

    const CustomToolbar = () => <Toolbar onOpenNav={onOpenNav} />

	return (
		<Stack
			sx={{
				position: 'relative',
				height: `100%`, //calc(100% - 80px)
			}}
		>
            <DataGrid
                columns={columns}
                height={'100%'} //680
                data={data}
                filtered={filtered}
                loading={isFetching}
                onRowClick={setSelectedRow}
                toolbar={CustomToolbar}
                style={{borderRadius: '10px'}}
            />
		</Stack>
    );
}

export default CrudList