import { APP_ALIAS } from "src/config/constants";
import { fetchDataOff, fetchDataOn } from "src/helpers/utils";

let Api = {
    getRows: async () =>
    {
        const endpoint = '/usuario/list';
		const body = null;
		const method = 'get';

		return fetchDataOn(endpoint, body, method);
    },
	getRow: async (cod_usuario) =>
    {
        const endpoint = `/usuario/get/${cod_usuario}`;
		const body = null;
		const method = 'get';

		return fetchDataOn(endpoint, body, method);
    },
	getDelete: async (cod_usuario) =>
    {
        const endpoint = `/usuario/remove/${cod_usuario}`;
		const body = null;
		const method = 'get';

		return fetchDataOn(endpoint, body, method);
    },
	getSession: async () =>
	{
		const endpoint = '/usuario/session';
		const body = null;
		const method = 'get';

		return await fetchDataOn(endpoint, body, method);
	},
	login: async (company, email, password) =>
    {
		localStorage.setItem(APP_ALIAS + "-company",company);

		const endpoint = "/usuario/login";
		const body = { email, password };
		const method = 'post';

		return await fetchDataOff(company, endpoint, body, method);
    },
	logoff: async () =>
	{
		const endpoint = '/usuario/logoff';
		const body = null;
		const method = 'get';

		return await fetchDataOn(endpoint, body, method);
	},
}

export default Api;
