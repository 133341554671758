import { APP_PATH_MAIN } from "src/config/constants";

function path(root, sublink) 
{
  	return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

export const PATH_AUTH = {
  	login: APP_PATH_MAIN + '/login',
};

export const PATH_DASHBOARD = {
	root: ROOTS_DASHBOARD,
	_403: {
		root: path(ROOTS_DASHBOARD, '/403')
	},
	address: {
		root: path(ROOTS_DASHBOARD, '/address'),
		list: path(ROOTS_DASHBOARD, '/address/list'),
		insert: path(ROOTS_DASHBOARD, '/address/insert'),
		edit: path(ROOTS_DASHBOARD, '/address/edit/:id'),
		delete: path(ROOTS_DASHBOARD, '/address/delete/:id'),
	},
	cashback: {
		root: path(ROOTS_DASHBOARD, '/cashback'),
		list: path(ROOTS_DASHBOARD, '/cashback/list'),
	},
	commission: {
		root: path(ROOTS_DASHBOARD, '/commission'),
		list: path(ROOTS_DASHBOARD, '/commission/list'),
		insert: path(ROOTS_DASHBOARD, '/commission/insert'),
		edit: path(ROOTS_DASHBOARD, '/commission/edit/:id'),
		delete: path(ROOTS_DASHBOARD, '/commission/delete/:id'),
	},
	coupon: {
		root: path(ROOTS_DASHBOARD, '/coupon'),
		redeem: path(ROOTS_DASHBOARD, '/coupon/redeem'),
		sale: path(ROOTS_DASHBOARD, '/coupon/sale'),
		delete: path(ROOTS_DASHBOARD, '/coupon/delete/:id'),
	},
	dashboard: {
		root: path(ROOTS_DASHBOARD, '/dashboard'),
		list: path(ROOTS_DASHBOARD, '/dashboard/list'),
	},
	event: {
		root: path(ROOTS_DASHBOARD, '/event'),
		pendent: path(ROOTS_DASHBOARD, '/event/pendent'),
	},
	graduation: {
		root: path(ROOTS_DASHBOARD, '/graduation'),
		list: path(ROOTS_DASHBOARD, '/graduation/list'),
	},
	participant: {
		root: path(ROOTS_DASHBOARD, '/participant'),
		list: path(ROOTS_DASHBOARD, '/participant/list'),
		listGraduation: path(ROOTS_DASHBOARD, '/participant/list/graduation/:id'),
		insert: path(ROOTS_DASHBOARD, '/participant/insert'),
		edit: path(ROOTS_DASHBOARD, '/participant/edit/:id'),
		delete: path(ROOTS_DASHBOARD, '/participant/delete/:id'),
		indicated: path(ROOTS_DASHBOARD, '/participant/indicated/:id'),
		event: path(ROOTS_DASHBOARD, '/participant/event'),
		eventId: path(ROOTS_DASHBOARD, '/participant/event/:id'),
		reedemPoints: path(ROOTS_DASHBOARD, '/participant/redeemPoints/:id'),
		myPoints: path(ROOTS_DASHBOARD, '/participant/MyPoints'),
	},
	sale: {
		root: path(ROOTS_DASHBOARD, '/sale'),
		list: path(ROOTS_DASHBOARD, '/sale/list'),
	},
	reward: {
		root: path(ROOTS_DASHBOARD, '/reward'),
		list: path(ROOTS_DASHBOARD, '/reward/list'),
	},
	tray: {
		root: path(ROOTS_DASHBOARD, '/tray'),
		list: path(ROOTS_DASHBOARD, '/tray/list'),
		insert: path(ROOTS_DASHBOARD, '/tray/insert'),
		edit: path(ROOTS_DASHBOARD, '/tray/edit/:id'),
	},
	user: {
		root: path(ROOTS_DASHBOARD, '/user'),
		list: path(ROOTS_DASHBOARD, '/user/list'),
		insert: path(ROOTS_DASHBOARD, '/user/insert'),
		edit: path(ROOTS_DASHBOARD, '/user/edit/:id'),
		delete: path(ROOTS_DASHBOARD, '/user/delete/:id'),
		logout: path(ROOTS_DASHBOARD, '/user/logout'),
	},
};
