import React, { createContext, useState, useEffect  } from 'react';
import { useQuery } from '@tanstack/react-query';

import ApiEvent from 'src/services/api/event';

import { useSnackbar } from 'src/components/snackbar';
import { getError } from 'src/helpers/utils';
import { orderBy } from 'lodash';

export const EventContext = createContext({});

export const EventProvider = ({children}) =>
{   
    const { enqueueSnackbar } = useSnackbar();

    const { data, isFetching, isError, error, refetch } = useQuery(['event-pendent'], 
        async () => await ApiEvent.getRowPendent(), 
        {
            //retry: 10,
            refetchInterval: 10000,
            refetchOnWindowFocus: false
        }
    );

    const [rows, setRows] = useState([]);

    useEffect(() =>
    {
        getRows();
    }, 
    // eslint-disable-next-line
    [data]);

    const getRows = () =>
    {
        if (data)
        {
            const { status, result } = data;
    
            if (status) 
                setRows(orderBy(result, ['cod_evento'], ['asc']));
        }
    }

    const markAsRead = async (cod_evento) =>
    {
        try 
        {
            const { status, result } = await ApiEvent.read(cod_evento);    

            if (status) 
            {
                refetch();

                return true;
            }
            else 
            {
                enqueueSnackbar(result, { variant: 'warning' });
                return false;
            }
        } 
        catch (error) 
        {
            const { result } = getError(error);
            enqueueSnackbar(result, { variant: 'error' });
            return false;
        }
    }

    const markAllAsRead = async () =>
    {
        try 
        {
            const { status, result } = await ApiEvent.readAll();    

            if (status) 
            {
                refetch();

                return true;
            }
            else 
            {
                enqueueSnackbar(result, { variant: 'warning' });
                return false;
            }
        } 
        catch (error) 
        {
            const { result } = getError(error);
            enqueueSnackbar(result, { variant: 'error' });
            return false;
        }
    }

    return (
        <EventContext.Provider
            value={
            {
                isFetching, isError, error, refetch,
                rows,
                markAsRead, markAllAsRead
            }}
        >
            {children}
        </EventContext.Provider>
    )
}