exports.tranformType = (field) =>
{
    const { name, type } = field;

    if (['text', 'array'].find(x => x === type))
        return { type: 'text-area' }
    else
    if (type.includes("varchar"))
    {
        const array = type.split('(');

        return {
            type: 'text',
            maxLength: array[1].replace(')','')
        }
    }
    else
    if (type === 'number')
    {
        return {
            type: 'number',
        }
    }
    else
    if (type === 'password')
    {
        return {
            type: 'password',
        }
    }
    else
    if (type === 'date')
    {
        return {
            type: 'date',
        }
    }
    else
    if (type === 'time')
    {
        return {
            type: 'time',
        }
    }
    else
    if (type.includes("enum("))
    {
        return {
            type: 'enum',
            items: this.normalizeItemsFromEnumToSelect(field)
        }
    }
    else
    if (type === 'int')
    {
        if (name.startsWith('cod'))
            return { type: 'select-api' }
        else
        {
            return {
                type: 'text',
                maxLength: 11
            }
        }
    }
    else
    {
        return {
            type: 'unknown',
            size: 0
        }
    }
}

exports.normalizeItemsFromEnumToSelect = (field) =>
{
    return field.items !== undefined
        ? field.items
        : field.type
        .replace("enum(","")
        .replace(")","")
        .replace(/[']+/g, '')
        .split(",")
        .map(item => { return { value: item.trim(), label: item.trim() }; });
}

exports.getInitialValues = (fields, data) =>
{
    var obj = {};

    fields.forEach(field => obj[field.name] = data ? data[field.name] : '');

    return obj;
}

exports.getSelects = (fields) =>
{
    return fields.filter(field =>
    {
        const { type, name } = field;

        return type === 'int' && name.startsWith("cod_")
    });
}

exports.getField = (fields, name) => fields.find(field => field.name === name);

exports.getFieldMainFromChild = (selects, select) => selects.find(x => x.name === select.main)

exports.parseTableName = (table) =>
{
    const replace = [ { from: 'pgr_minimo', to: 'pgr' }];
    const toReplace = replace.find(x => x.from === table);

    if (toReplace)
        table = table.replace(toReplace.from, toReplace.to);

    return table;
}

exports.getPermission = (params, field) =>
{
    const { insert, update } = field.permission;

    return params.id ? update : insert;
}

exports.getLabel = (field) =>
{
    console.log(field.items, field)

    return ""
}

exports.parseValuesToSend = (fields, values, params) =>
{
    let parsedValues = {};

    Object.keys(values).forEach(key =>
    {
        const field = this.getField(fields, key);

        if (this.getPermission(params, field))
            parsedValues[key] = values[key];
    });

    return parsedValues;
}