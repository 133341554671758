import React from 'react';
import { Grid } from '@mui/material';

import { useForm } from 'src/context/FormReusable/useForm';
import Field from './Field';
import LoadingMutation from './LoadingMutation';
import LoadingQuery from './LoadingQuery';
import LoadingSelect from './LoadingSelect';

const Fields = () => 
{
    const { query, mutation, selectStatus, fields, values, onChange } = useForm();

    return (
        <>
            <LoadingQuery />
            <LoadingMutation />
            <LoadingSelect />
            {
                (!query.isFetching || !query.isLoading) &&
                !mutation.isLoading &&
                // !mutation.isError &&
                // !mutation.isSuccess &&
                !selectStatus.status && 
                fields &&
                <Grid container spacing={3}>
                    {
                        fields.map((field, i) =>
                            <Field
                                key={i}
                                field={field}
                                value={values !== undefined && values !== null ? values[field.name] : ''}
                                required={field.required}
                                visible={field.visible}
                                onChange={onChange}
                                helper={field?.helper}
                            />
                        )
                    }
                </Grid>
            }
        </>
    )
}

export default Fields