import { Suspense, lazy } from 'react';

import LoadingScreen from '../components/loading-screen';

const Loadable = (Component) => (props) =>
(
	<Suspense fallback={<LoadingScreen />}>
		<Component {...props} />
	</Suspense>
);

export const Address = Loadable(lazy(() => import('../pages/address'))); 
export const AddressEdit = Loadable(lazy(() => import('../pages/address/Edit'))); 

export const Cashback = Loadable(lazy(() => import('../pages/cashback'))); 

export const Commission = Loadable(lazy(() => import('../pages/commission'))); 
export const CommissionEdit = Loadable(lazy(() => import('../pages/commission/Edit'))); 
export const CommissionDelete = Loadable(lazy(() => import('../pages/commission/Delete'))); 

export const Coupon = Loadable(lazy(() => import('../pages/coupon'))); 
export const CouponSale = Loadable(lazy(() => import('../pages/coupon/Sale'))); 
export const CouponDelete = Loadable(lazy(() => import('../pages/coupon/Delete'))); 

export const Dashboard = Loadable(lazy(() => import('../pages/dashboard'))); 

export const EventPendent = Loadable(lazy(() => import('../pages/event/Pendent'))); 

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));

export const Graduation = Loadable(lazy(() => import('../pages/graduation'))); 

export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const Participant = Loadable(lazy(() => import('../pages/participant'))); 
export const ParticipantEdit = Loadable(lazy(() => import('../pages/participant/Edit'))); 
export const ParticipantDelete = Loadable(lazy(() => import('../pages/participant/Delete'))); 
export const ParticipantIndicated = Loadable(lazy(() => import('../pages/participant/Indicated'))); 
export const ParticipantEvent = Loadable(lazy(() => import('../pages/participant/Event'))); 
export const ParticipantRedeemPoints = Loadable(lazy(() => import('../pages/cashback/Redeem'))); 

export const Sale = Loadable(lazy(() => import('../pages/sale'))); 

export const Reward = Loadable(lazy(() => import('../pages/reward'))); 

export const Tray = Loadable(lazy(() => import('../pages/tray'))); 
export const TrayEdit = Loadable(lazy(() => import('../pages/tray/Edit'))); 

export const User = Loadable(lazy(() => import('../pages/user'))); 
export const UserEdit = Loadable(lazy(() => import('../pages/user/Edit'))); 
export const UserDelete = Loadable(lazy(() => import('../pages/user/Delete'))); 
export const UserProfile = Loadable(lazy(() => import('../pages/user/Profile'))); 
export const UserLogout = Loadable(lazy(() => import('../pages/user/Logout')));
