import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';

import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Form from './Form';

import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSettingsContext } from 'src/components/settings';
import { FormProvider } from 'src/context/FormReusable';
import { IconButton } from '..';
import { getClientName } from 'src/helpers/servers';
import { toDecrypt } from 'src/helpers/utils';
import { APP_NAME } from 'src/config/constants'
import { Stack } from '@mui/material';

const Index = ({id, title, table, fields, optionsButtonsList=[], permission}) => 
{
    const { themeStretch } = useSettingsContext();
 
    const navigate = useNavigate();
    const params = useParams();

    const [show, setShow] = useState(false);

    useEffect(() =>
    {
        checkPermission();

        document.body.style.overflow = 'auto';
    }, 
    //eslint-disable-next-line
    []);

    const checkPermission = () =>
    {
        if (permission)
        {
            // const { category, menu, item } = permission;
            
            // if (!hasPermission(permissions, category, menu, item))
            //     navigate(PATH_DASHBOARD._403.root);
            // else
                setShow(true);
        }
        else 
            setShow(true);
    }

    return show && (
        <FormProvider idPage={id} title={title} table={table} fieldsInit={fields} optionsButtonsList={optionsButtonsList}>
            <Helmet>
                <title>{title} | {APP_NAME} | {getClientName()}</title>
            </Helmet>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <Stack style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} sx={{mb: 5}}>
                    <CustomBreadcrumbs
                        heading={title}
                        links={[
                            {
                                name: 'Dashboard',
                                href: PATH_DASHBOARD.root,
                            },
                            {
                                name: title,
                                href: PATH_DASHBOARD[id].list,
                            },
                            { name: params.id ? `ID: ${toDecrypt(params.id).padStart(6,'0')}` : "Cadastro"}, 
                        ]}
                    />
                    <IconButton icon="ri:arrow-go-back-fill" title="Voltar à tela anterior" onClick={() => navigate(-1)} />
                </Stack>
                <Form />
            </Container>
        </FormProvider>
    )
}

Index.prototype = 
{
    id: PropType.string,
    title: PropType.string,
    table: PropType.string,
    fields: PropType.array,
    permission: PropType.object
};

export default Index